import React, { Component } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { AppState } from "../../redux/store";
import { NotificationState } from "../../redux/store/notification/types";
import { toggleGlobalDialogOpen } from "../../redux/store/notification/actions";
import ResponsiveDialog from "../ResponsiveDialog";

class GlobalDialog extends Component<{
  notification: NotificationState;
  toggleGlobalDialogOpen: typeof toggleGlobalDialogOpen;
}> {
  render() {
    const { notification, toggleGlobalDialogOpen } = this.props;
    return (
      <ResponsiveDialog
        fullWidth
        open={notification.globalDialog.open}
        onClose={() => {
          toggleGlobalDialogOpen();
        }}
        aria-labelledby="global-dialog-title"
      >
        <DialogTitle id="global-dialog-title">
          {notification.globalDialog.title}
        </DialogTitle>
        <DialogContent>{notification.globalDialog.message}</DialogContent>
        <DialogActions>
          <Button onClick={toggleGlobalDialogOpen} color="primary">
            Okay
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  notification: state.notification,
});

export default connect(mapStateToProps, { toggleGlobalDialogOpen })(
  GlobalDialog
);
