import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Empty from "../../layouts/Empty";
import Container from "@mui/material/Container";
import { CheckoutLocationState } from ".";
import { AccountState } from "../../redux/store/account/types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PlanInfo } from "../../../../common";
import PlanInfoCard from "../../components/checkout/PlanInfoCard";
import { Link, useHistory, useLocation } from "react-router-dom";
import { previewUserPlan } from "../../redux/store/account/actions";
import { useDispatch } from "react-redux";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/system/Box";

interface SelectPlanProps {
  account: AccountState;
  colState: CheckoutLocationState;
  setState: (state: CheckoutLocationState) => any;
}

const SelectPlan: React.FC<SelectPlanProps> = ({
  colState,
  account,
  setState,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  // Helper to select the current price id after a term change using state
  const updatePriceId = (term: "monthly" | "annual") => {
    const currentPlan = account.availablePlans
      ?.filter((p) => p.id === colState.plan)
      .pop();
    return term === "annual"
      ? currentPlan?.annualPriceId
      : currentPlan?.monthlyPriceId;
  };

  useEffect(() => {
    if (colState.plan && colState.price) {
      dispatch(
        previewUserPlan({
          newPlanId: colState.plan,
          newPriceId: colState.price,
          subscriptionId: account.currentSubscription?.id,
        })
      );
    }
  }, [
    colState.plan,
    colState.price,
    colState.term,
    account.currentSubscription?.id,
    dispatch,
  ]);

  const filteredPlans = (account.availablePlans || []).filter((p) => {
    // Don't filter the view if they are legacy
    if (account?.currentSubscription?.plan?.legacy) {
      return true;
    }
    // Compare prices
    if (account.currentSubscription?.term === "annual") {
      return (
        p.annualPrice > (account.currentSubscription?.plan?.annualPrice || 0)
      );
    } else {
      if (colState.term === "annual") {
        return (
          p.annualPrice > (account.currentSubscription?.plan?.monthlyPrice || 0)
        );
      } else {
        return (
          p.monthlyPrice >
          (account.currentSubscription?.plan?.monthlyPrice || 0)
        );
      }
    }
  });
  return (
    <Empty>
      <Helmet title="Select Plan" />
      <Container maxWidth="md">
        <Box sx={{ my: 3 }}>
          <Button
            startIcon={<ArrowBack />}
            component={Link}
            to="/account#Subscription"
          >
            Cancel
          </Button>
        </Box>
        <Grid container spacing={3} justifyContent="center">
          {filteredPlans.map((p) => (
            <Grid
              item
              key={p.id}
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PlanInfoCard
                plan={p}
                annual={
                  colState.term === "annual" ||
                  (p.id === account.currentSubscription?.plan?.id &&
                    account.currentSubscription.term === "annual")
                }
                currentPlan={
                  p.id === account.currentSubscription?.plan?.id &&
                  colState.term === account.currentSubscription.term
                }
                disabled={
                  p.id === account.currentSubscription?.plan?.id &&
                  colState.term === account.currentSubscription.term
                }
                onSelect={(p: PlanInfo) => {
                  setState({
                    ...colState,
                    plan: p.id,
                    price:
                      colState.term === "annual"
                        ? p.annualPriceId
                        : p.monthlyPriceId,
                  });
                }}
                selected={colState.plan === p.id}
              />
            </Grid>
          ))}
          {account.currentSubscription && filteredPlans.length <= 2 && (
            <Grid item key={"raw-data"}>
              <PlanInfoCard
                plan={{
                  id: "raw-data",
                  name: "Raw Data",
                  queries: 0,
                  annualPrice: 0,
                  annualPriceId: "n/a",
                  monthlyPrice: 0,
                  monthlyPriceId: "n/a",
                  description:
                    "Bulk access to our IP Context data. Ideal for fraud prevention, machine learning, real-time applications, and on-prem environments.",
                }}
                contactUs
                annual={colState.term === "annual"}
                onSelect={(p: PlanInfo) => {}}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                py: 2,
                pr: 4,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={colState.term === "annual"}
                    onChange={() => {
                      const newTerm =
                        colState.term === "annual" ? "monthly" : "annual";
                      setState({
                        ...colState,
                        price: updatePriceId(newTerm),
                        term: newTerm,
                      });
                    }}
                    id="tos"
                    name="tos"
                    color="primary"
                    size="small"
                  />
                }
                disabled={account.currentSubscription?.term === "annual"}
                label={
                  <Typography
                    variant="caption"
                    color="secondary"
                    style={{ textAlign: "left" }}
                  >
                    Bill Annually
                  </Typography>
                }
              />
            </Box>
          </Grid>
        </Grid>
        <div style={{ textAlign: "center", paddingTop: 40 }}>
          <Button
            fullWidth
            sx={{ maxWidth: 300 }}
            color="primary"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={async () => {
              history.push({ ...location, pathname: "/checkout/payment" });
            }}
            disabled={
              !colState.plan ||
              !colState.price ||
              colState.price === account.currentSubscription?.priceId
            }
          >
            Continue
          </Button>
        </div>
      </Container>
    </Empty>
  );
};

export default SelectPlan;
