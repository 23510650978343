import React, { ReactElement } from "react";
import Stack, { StackProps } from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { CardContent } from "@mui/material";

interface SectionProps {
  title: string;
  subtitle?: string | ReactElement;
  id?: string;
}

const Section: React.FC<StackProps & SectionProps> = ({
  title,
  subtitle,
  children,
  sx = [],
  ...rest
}) => {
  return (
    <Card>
      <CardHeader title={title} subheader={subtitle} />
      <CardContent>
        <Stack id={title} {...rest}>
          <Stack gap={2}>{children}</Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Section;
