import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

let config;

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  config = {
    apiKey: "AIzaSyBj3TdbafNunMDVPI2iKGOQr6f1AwCY1AI",
    authDomain: "spur-us-prod.firebaseapp.com",
    databaseURL: "https://spur-us-prod.firebaseio.com",
    projectId: "spur-us-prod",
    storageBucket: "spur-us-prod.appspot.com",
    messagingSenderId: "176475145474",
    appId: "1:176475145474:web:f778d1539ab4f8a10f2e1b",
    measurementId: "G-ZD7Y9QH5J3",
  };
} else {
  config = {
    apiKey: "AIzaSyDWiPFLCB3CQ0s6m2jP8wLwJYwTrXEEq5k",
    authDomain: "spur-us-dev.firebaseapp.com",
    databaseURL: "https://spur-us-dev.firebaseio.com",
    projectId: "spur-us-dev",
    storageBucket: "spur-us-dev.appspot.com",
    messagingSenderId: "891496027627",
    appId: "1:891496027627:web:c95ffb36d1a70dd2d935ae",
    measurementId: "G-RDMV244687",
  };
}

firebase.initializeApp(config);
export const app = firebase.app();
export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// TODO: Migrate to v9 once auth bugs fixed
// export const app = initializeApp(config);
// export const db = getFirestore(app);
// export const auth = getAuth(app);
// export const functions = getFunctions(app);
// auth.setPersistence(browserLocalPersistence);

// Assume if running local you are also emulating functions
if (process.env.NODE_ENV !== "production") {
  functions.useEmulator("localhost", 5001);
}

if (process.env.USE_LOCAL_FB_AUTH) {
  auth.useEmulator("http://localhost:9099/auth");
}
