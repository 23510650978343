import {
  createTheme,
  PaletteOptions,
  responsiveFontSizes,
} from "@mui/material/styles";

// This allows us to override some typescript for our themes
// and inject other options
// declare module "@mui/material/styles/createTheme" {
//   interface Theme {
//     breakpoints: {
//       values: {
//         zoomed: number;
//         wide: number;
//         huge: number;
//       };
//     };
//   }
// }

// This allows us to add custom colors to the palette
// declare module "@mui/material/styles/createPalette" {
//   interface Palette {
//     // alert: Palette["primary"];
//     // neutral: Palette["primary"];
//   }
//   interface PaletteOptions {
//     // alert: PaletteOptions["primary"];
//     // neutral: PaletteOptions["primary"];
//   }
// }

export const SpurPalette = {
  black: "#000000",
  almostBlack: "#171718",
  white: "#ffffff",
  // almostWhite: "#e9e9e9",
  almostWhite: "#EEEEEE",
  medGrey: "#888888",
  purple: "#6A48F2",
  lightPurple: "#CEA4EF",
};

// const SpurMuiDarkPalette: PaletteOptions = {
//   mode: "dark",
//   background: {
//     default: SpurPalette.black,
//     paper: SpurPalette.almostBlack,
//   },
//   primary: {
//     main: SpurPalette.white,
//   },
//   secondary: {
//     main: SpurPalette.purple,
//   },
// };

const SpurMuiLightPalette: PaletteOptions = {
  background: {
    default: SpurPalette.almostWhite,
    // paper: SpurPalette.almostBlack,
  },
  primary: {
    main: SpurPalette.black,
  },
  secondary: {
    main: SpurPalette.purple,
  },
  action: {
    // active: "orange",
    // activeOpacity: 1,
    // hover: lightBlue[100],
    // hoverOpacity: 0.7,
    // focus: lightBlue[600],
    // focusOpacity: 1,
    // selected: lightBlue[300],
    // selectedOpacity: 1,
  },
};

const defaultTheme = responsiveFontSizes(
  createTheme({
    spacing: 6,
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            fontSize: "smaller",
            textTransform: "capitalize",
            maxWidth: "15vw",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            fontWeight: "bold",
            variant: "h4",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: "smaller",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "smaller",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: "smaller",
            textTransform: "capitalize",
          },
        },
      },
    },
    // palette: SpurMuiDarkPalette,
    palette: SpurMuiLightPalette,
    typography: {
      fontFamily: "Inter",
      h1: {
        fontFamily: "Helvetica",
      },
      h2: {
        fontFamily: "Helvetica",
      },
      h3: {
        fontFamily: "Helvetica",
      },
      h4: {
        fontFamily: "Helvetica",
      },
    },
    shape: {
      borderRadius: 20,
    },
  })
);

export default defaultTheme;
