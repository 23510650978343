import React, { useContext, FunctionComponent, lazy } from "react";
import { AuthContext } from "../contexts/Auth";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import Empty from "../layouts/Empty";
import Checkout from "../pages/Checkout";
import LazyLoadErrorBoundary from "./LazyLoadErrorBoundary";

// Public
const Start = lazy(() => import("../pages/Start"));
const Landing = lazy(() => import("../pages/Auth/landing"));
const SignIn = lazy(() => import("../pages/Auth/SignIn"));
const ResetPassword = lazy(() => import("../pages/Auth/ResetPassword"));

// Private
const Context = lazy(() => import("../pages/Context"));
const Account = lazy(() => import("../pages/Account"));
const Monocle = lazy(() => import("../pages/Monocle"));
const MonocleFormDemo = lazy(() => import("../pages/Demos/MonocleFormDemo"));
const NotFound = lazy(() => import("../pages/NotFound"));

export const PrivateRoute: FunctionComponent<RouteProps> = ({
  location,
  children,
  ...rest
}) => {
  const { user }: any = useContext(AuthContext);
  if (user && user.email) {
    return <Route {...rest} />;
  } else {
    return (
      <Redirect to={{ pathname: "/auth/sign-in", state: { from: location } }} />
    );
  }
};

const routeDefinitions = [
  {
    id: "auth-signin",
    path: "/auth/sign-in",
    component: <SignIn />,
    exact: true,
  },
  {
    id: "auth-landing",
    path: "/auth/landing",
    component: <Landing />,
    exact: true,
  },

  {
    id: "auth-reset-password",
    path: "/auth/reset-password",
    component: <ResetPassword />,
    exact: true,
  },
  {
    id: "checkout",
    path: "/checkout/",
    component: <Checkout />,
    private: true,
  },
  {
    id: "start",
    path: "/start/",
    component: <Start />,
  },
  {
    id: "context",
    path: "/context",
    private: true,
    component: <Context />,
    exact: true,
  },
  {
    id: "account",
    path: "/account",
    private: true,
    component: <Account />,
    exact: true,
  },
  {
    id: "monocle",
    path: "/monocle",
    private: true,
    component: <Monocle />,
    // exact: true,
  },
  {
    id: "demos-monocle-form-demo",
    path: "/demos/monocle/form",
    // private: true,
    component: <MonocleFormDemo />,
    exact: true,
  },
];

const Routes = () => {
  const handleChunkLoadError = () => {
    window.location.reload();
  };
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <LazyLoadErrorBoundary onChunkLoadError={handleChunkLoadError}>
        <Switch>
          <PrivateRoute
            key="home"
            exact
            path="/"
            render={({ location }) => {
              return <Redirect to={{ ...location, pathname: "/context" }} />;
            }}
          />
          {routeDefinitions.map((r) => {
            return r.private ? (
              <PrivateRoute
                key={r.id}
                exact={r.exact}
                path={r.path}
                render={() => {
                  return r.component;
                }}
              />
            ) : (
              <Route
                key={r.id}
                exact={r.exact}
                path={r.path}
                render={() => {
                  return r.component;
                }}
              />
            );
          })}
          <Route
            render={() => (
              <Empty>
                <NotFound />
              </Empty>
            )}
          />
        </Switch>
      </LazyLoadErrorBoundary>
    </BrowserRouter>
  );
};

export default Routes;
