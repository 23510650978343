export const siteDomain = `${process.env.REACT_APP_SITE_DOMAIN}`;

export const siteURL = `${process.env.REACT_APP_SITE_URL}`;

export const siteTransport =
  process.env.REACT_APP_ENVIRONMENT === "development" ? "http" : "https";

export const LINK_AUTH_TMP_EMAIL_LOC = "emailForSignIn";

export const monocleSiteToken = `${process.env.REACT_APP_MONOCLE_SITE_TOKEN}`;
export const monocleDemoSiteToken = `${process.env.REACT_APP_MONOCLE_DEMO_SITE_TOKEN}`;

export const buildNumber = `${process.env.REACT_APP_BUILD_NUMBER}`;
