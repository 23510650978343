import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Empty from "../../layouts/Empty";
import Container from "@mui/material/Container";
import { CheckoutLocationState } from ".";
import { AccountState } from "../../redux/store/account/types";
import Button from "@mui/material/Button";
import { ArrowBack, Home } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { InvoiceSummary, PlanInfo } from "../../../../common";
import { fetchJSON } from "../../lib/fetch";
import PlanInfoCard from "../../components/checkout/PlanInfoCard";
import Section from "../../components/Section";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

interface CompleteProps {
  account: AccountState;
  colState: CheckoutLocationState;
  setState: (state: CheckoutLocationState) => any;
}

const Complete: React.FC<CompleteProps> = ({ colState, account, setState }) => {
  const [localState, setLocalState] = useState<{
    planInfo?: PlanInfo;
    invoice?: InvoiceSummary;
  }>({});

  const theme = useTheme();

  useEffect(() => {
    if (colState.plan) {
      fetchJSON(`/api/v1/plans/${colState.plan}`).then(({ data }) => {
        setLocalState((s) => ({ ...s, planInfo: data }));
      });
    }
    if (colState.invId) {
      fetchJSON(`/api/v1/invoices/${colState.invId}`).then(({ data }) => {
        setLocalState((s) => ({ ...s, invoice: data }));
      });
    }
  }, [colState.plan, colState.invId]);

  // console.log(colState.status);

  return (
    <Empty>
      <Helmet title="Checkout Complete" />
      <Container maxWidth="md">
        <Button startIcon={<Home />} component={RouterLink} to={"/"}>
          Home
        </Button>
        {colState.status === "success" ? (
          <div>
            {localState.invoice ? (
              <Section
                title={"Purchase Complete"}
                subtitle={
                  <span>
                    click{" "}
                    <Link
                      underline="always"
                      href={localState.invoice.webUrl}
                      target="_blank"
                      referrerPolicy="no-referrer"
                    >
                      here
                    </Link>{" "}
                    to view your invoice
                  </span>
                }
              />
            ) : (
              <Section
                title={"Purchase Complete"}
                subtitle={
                  <span>
                    click{" "}
                    <RouterLink
                      color="secondary"
                      style={{
                        color: theme.palette.secondary.main,
                      }}
                      to="/account#Billing"
                    >
                      here
                    </RouterLink>{" "}
                    to view your invoice
                  </span>
                }
              />
            )}
            {localState.planInfo && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                    gap: theme.spacing(2),
                  }}
                >
                  {localState.planInfo && (
                    <PlanInfoCard
                      plan={localState.planInfo}
                      currentPlan={true}
                      selected={true}
                      annual={localState.invoice?.term === "annual"}
                      disabled={true}
                      onSelect={() => {}}
                    />
                  )}

                  <Button
                    style={{ maxWidth: 300 }}
                    component={RouterLink}
                    to={"/account#Tokens"}
                    variant="contained"
                    color="primary"
                  >
                    Manage Access Tokens
                  </Button>
                  <Button
                    style={{ maxWidth: 300 }}
                    component={RouterLink}
                    to={"/account#Subscription"}
                    variant="contained"
                    color="primary"
                  >
                    Download The Docs
                  </Button>
                  <Button
                    style={{ maxWidth: 300 }}
                    component={RouterLink}
                    to={"/context"}
                    variant="contained"
                    color="primary"
                  >
                    Search for Data
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                gap: theme.spacing(2),
              }}
            >
              <Typography variant="body2">An error occurred.</Typography>
              <Button
                style={{ maxWidth: 300 }}
                component={RouterLink}
                to={"/account"}
                variant="contained"
                color="primary"
                startIcon={<ArrowBack />}
              >
                Back to Account
              </Button>
            </div>
          </div>
        )}
      </Container>
    </Empty>
  );
};

export default Complete;
