import React from "react";
import {
  Dialog,
  DialogProps,
  useTheme,
  useMediaQuery,
  Fab,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Box from "@mui/system/Box";

export interface ResponsiveDialogProps extends DialogProps {
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl";
  closeFab?: boolean;
}

const ResponsiveDialog: React.FunctionComponent<ResponsiveDialogProps> = ({
  breakpoint = "sm",
  closeFab,
  children,
  fullScreen,
  onClose,
  ...rest
}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down(breakpoint));
  return (
    <Dialog fullScreen={fullScreen || isFullScreen} {...rest} onClose={onClose}>
      {closeFab && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
            pr: 3,
            pt: 2,
          }}
        >
          <Fab
            size="small"
            aria-label="close checkout"
            onClick={() => {
              if (onClose) {
                onClose({}, "escapeKeyDown");
              }
            }}
          >
            <Close />
          </Fab>
        </Box>
      )}
      {children}
    </Dialog>
  );
};

export default ResponsiveDialog;
