import {
  PlanInfo,
  Token,
  PaymentMethodSummary,
  UserSummary,
  SubscriptionInfo,
  InvoicePreview,
} from "../../../../../common";

export interface AccountState {
  user?: UserSummary;
  tokens?: Token[];

  currentSubscription?: SubscriptionInfo;
  currentSubscriptionFetching?: boolean;

  subscriptionChangePreview?: InvoicePreview;
  subscriptionChangePreviewFetching?: boolean;

  availablePlans: PlanInfo[] | undefined;
  availablePlansFetching?: boolean;

  defaultPaymentMethod?: PaymentMethodSummary;
  paymentMethods?: PaymentMethodSummary[];
}

// Account Actions
export const SET_AVAILABLE_PLANS = "account/SET_AVAILABLE_PLANS";
export const SET_AVAILABLE_PLANS_FETCHING =
  "account/SET_AVAILABLE_PLANS_FETCHING";

export const SET_CURRENT_SUBSCRIPTION = "account/SET_CURRENT_SUBSCRIPTION";
export const SET_CURRENT_SUBSCRIPTION_FETCHING =
  "account/SET_CURRENT_SUBSCRIPTION_FETCHING";

export const SET_SUBSCRIPTION_CHANGE_PREVIEW =
  "account/SET_SUBSCRIPTION_CHANGE_PREVIEW";
export const SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING =
  "account/SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING";

export const SET_PAYMENT_METHODS = "account/SET_PAYMENT_METHODS";

export const SET_DEFAULT_PAYMENT_METHOD = "account/SET_DEFAULT_PAYMENT_METHOD";
export const SET_TOKENS = "account/SET_TOKENS";
export const UPDATE_TOKEN = "account/UPDATE_TOKEN";
export const SET_USER = "account/SET_USER";

interface SetAvailablePlansAction {
  type: typeof SET_AVAILABLE_PLANS;
  payload: PlanInfo[] | undefined;
}

interface SetAvailablePlansFetchingAction {
  type: typeof SET_AVAILABLE_PLANS_FETCHING;
  payload: boolean;
}

interface SetDefaultPaymentMethod {
  type: typeof SET_DEFAULT_PAYMENT_METHOD;
  payload: PaymentMethodSummary | undefined;
}

interface SetPaymentMethods {
  type: typeof SET_PAYMENT_METHODS;
  payload: PaymentMethodSummary[] | undefined;
}

interface SetSubscriptionChangePreviewAction {
  type: typeof SET_SUBSCRIPTION_CHANGE_PREVIEW;
  payload: InvoicePreview | undefined;
}

interface SetSubscriptionChangePreviewFetchingAction {
  type: typeof SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING;
  payload: boolean | undefined;
}

interface SetCurrentSubscriptionAction {
  type: typeof SET_CURRENT_SUBSCRIPTION;
  payload: SubscriptionInfo | undefined;
}

interface SetCurrentSubscriptionFetchingAction {
  type: typeof SET_CURRENT_SUBSCRIPTION_FETCHING;
  payload: boolean | undefined;
}

interface SetTokensAction {
  type: typeof SET_TOKENS;
  payload: Token[] | undefined;
}

interface SetUserAction {
  type: typeof SET_USER;
  payload: UserSummary | undefined;
}

interface UpdateTokenAction {
  type: typeof UPDATE_TOKEN;
  payload: Token;
}

// Global actions
export const LOGOUT = "global/LOGOUT";

interface LogoutAction {
  type: typeof LOGOUT;
}

export const RESET_STORE = "global/RESET_STORE";

export type AccountActionTypes =
  | LogoutAction
  | SetAvailablePlansAction
  | SetAvailablePlansFetchingAction
  | SetCurrentSubscriptionAction
  | SetCurrentSubscriptionFetchingAction
  | SetDefaultPaymentMethod
  | SetPaymentMethods
  | SetSubscriptionChangePreviewAction
  | SetSubscriptionChangePreviewFetchingAction
  | SetTokensAction
  | SetUserAction
  | UpdateTokenAction;

export interface AccountActionOptions {
  notify?: boolean;
}
