import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Empty from "../layouts/Empty";
import { MultiSchemaHelmet } from "./MultiSchemaHelmet";
import Button from "@mui/material/Button";

const AppUpdatedErrorMessage = () => (
  <Empty>
    <MultiSchemaHelmet
      title="App Updated"
      description="This page was not found or is not currently available."
      canonical="/error-app-updated"
    />
    <Typography component="h1" variant="h1" align="center" gutterBottom>
      Not Found
    </Typography>
    <Typography component="h2" variant="h5" align="center" gutterBottom>
      Our website has been updated
    </Typography>
    <Box mt={5} display="flex" justifyContent="center">
      <Typography
        component="h2"
        variant="body1"
        align="center"
        gutterBottom
        maxWidth={"sm"}
      >
        This page has been updated. Please refresh your page to load the latest
        version.
      </Typography>
    </Box>
    <Box mt={5} display="flex" justifyContent="center">
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload Page
      </Button>
    </Box>
  </Empty>
);

export default AppUpdatedErrorMessage;
