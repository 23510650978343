import React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { appear } from "./Animations";
import Div from "./Div";

interface CustomLoaderProps extends CircularProgressProps {
  delay?: boolean;
}

const Loader: React.FC<CustomLoaderProps> = ({ delay, ...rest }) => {
  return (
    <Div
      sx={{
        width: "100%",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        ...(delay && {
          visibility: "hidden",
          animation: `${appear} 0s 2.5s forwards`,
        }),
      }}
    >
      <CircularProgress color="secondary" {...rest} />
    </Div>
  );
};

export default Loader;
