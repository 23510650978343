import {
  faBuilding,
  faCity,
  faCode,
  faFileCode,
  faHandsHelping,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";
import { PlanInfo } from "../../../../common";
import { formatCurrency } from "../../locale";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";

interface PlanInfoCardProps {
  plan: PlanInfo;
  onSelect: (p: PlanInfo) => any;
  annual?: boolean;
  selected?: boolean;
  disabled?: boolean;
  currentPlan?: boolean;
  contactUs?: boolean;
}

export const PlanInfoCard: React.FC<PlanInfoCardProps> = ({
  plan,
  onSelect,
  annual,
  selected,
  disabled,
  contactUs,
  currentPlan,
}) => {
  let icon = faCode;
  switch (true) {
    case plan.id === "community":
      icon = faHandsHelping;
      break;
    case plan.id === "50k-pack":
      icon = faUsers;
      break;
    case plan.id === "250k-pack":
      icon = faBuilding;
      break;
    case plan.id === "1m-pack":
      icon = faCity;
      break;
    case plan.id === "raw-data":
      icon = faFileCode;
      break;
  }

  let price;
  if (annual) {
    price = formatCurrency(Math.ceil(plan.annualPrice / 12));
  } else {
    price = formatCurrency(Math.ceil(plan.monthlyPrice));
  }

  return (
    <Card
      component={Stack}
      gap={3}
      alignItems="center"
      sx={(t) => ({
        width: 240,
        // border: `2px solid ${t.palette.primary.main}`,
        // boxShadow: t.shadows[1],
        minHeight: 500,
        p: 4,
        transition: "all .3s",
        ...(selected && {
          width: 260,
          color: t.palette.primary.contrastText,
          backgroundColor: t.palette.primary.main,
          border: `2px solid ${t.palette.primary.main}`,
        }),
      })}
    >
      <Typography sx={{ fontFamily: "DDC", fontSize: "1.1rem", pb: 2 }}>
        {plan.name}
      </Typography>
      <FontAwesomeIcon icon={icon} size="3x" />
      {currentPlan || contactUs ? (
        <Box sx={{ m: 1, textAlign: "center" }}>
          <Typography variant="h5" sx={{ display: "inline-block" }}>
            {currentPlan ? "Current Plan" : "Contact Us"}
          </Typography>
          <Typography
            sx={{ display: "block" }}
            color="secondary"
            variant="caption"
          >
            {annual ? "(billed annually)" : "(billed monthly)"}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ m: 1 }}>
          <Typography variant="h5" style={{ display: "inline-block" }}>
            {price}
          </Typography>
          <Typography variant="caption" style={{ display: "inline-block" }}>
            /mo
          </Typography>
          <Typography
            style={{ display: "block" }}
            color="secondary"
            variant="caption"
          >
            {annual ? "(billed annually)" : "(billed monthly)"}
          </Typography>
        </Box>
      )}

      <Divider
        sx={(t) => ({
          width: "100%",
          maxWidth: 360,
          backgroundColor: selected
            ? t.palette.primary.contrastText
            : t.palette.secondary.main,
        })}
      />
      <Box sx={{ minHeight: 120 }}>
        <Typography variant="caption">{plan.description}</Typography>
      </Box>
      {contactUs ? (
        <a
          href="mailto:sales@spur.us?subject=Raw Data"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button fullWidth variant="contained">
            Get in Touch
          </Button>
        </a>
      ) : (
        <Button
          fullWidth
          variant="contained"
          disabled={selected || disabled}
          sx={(t) => ({
            ...(selected && {
              backgroundColor: t.palette.primary.contrastText,
            }),
          })}
          onClick={() => {
            onSelect(plan);
          }}
        >
          {currentPlan ? "Current Plan" : selected ? "Selected" : "Select"}
        </Button>
      )}
    </Card>
  );
};

export default PlanInfoCard;
