export const formatCurrency = (n: number, decimals?: boolean): string => {
  return n.toLocaleString(undefined, {
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
    style: "currency",
    currency: "USD",
  });
};

export const formatNumber = (n: number): string => {
  return n.toLocaleString(undefined, {});
};
