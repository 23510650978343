import {
  GlobalDialogState,
  GlobalErrorDialogState,
  GlobalToastState,
  SET_GLOBAL_DIALOG_STATE,
  SET_GLOBAL_ERROR_DIALOG_STATE,
  SET_GLOBAL_TOAST_STATE,
  TOGGLE_GLOBAL_DIALOG_STATE_OPEN,
  TOGGLE_GLOBAL_ERROR_DIALOG_STATE_OPEN,
  TOGGLE_GLOBAL_TOAST_STATE_OPEN,
} from "./types";

export function setGlobalDialog(state: GlobalDialogState) {
  return {
    type: SET_GLOBAL_DIALOG_STATE,
    payload: state,
  };
}

export function toggleGlobalDialogOpen() {
  return {
    type: TOGGLE_GLOBAL_DIALOG_STATE_OPEN,
  };
}

export function setGlobalErrorDialog(state: GlobalErrorDialogState) {
  return {
    type: SET_GLOBAL_ERROR_DIALOG_STATE,
    payload: state,
  };
}

export function toggleGlobalErrorDialogOpen() {
  return {
    type: TOGGLE_GLOBAL_ERROR_DIALOG_STATE_OPEN,
  };
}

export function toggleGlobalToastOpen() {
  return {
    type: TOGGLE_GLOBAL_TOAST_STATE_OPEN,
  };
}

export function setGlobalToast(state: GlobalToastState) {
  return {
    type: SET_GLOBAL_TOAST_STATE,
    payload: state,
  };
}
