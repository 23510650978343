import "./firebase";
import "./App.css";
import React, { Suspense } from "react";
import Helmet from "react-helmet";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import { AuthProvider } from "./contexts/Auth";
import theme from "./theme";

import GlobalDialog from "./components/notification/GlobalDialog";
import GlobalToast from "./components/notification/GlobalToast";
import GlobalErrorDialog from "./components/notification/GlobalErrorDialog";
import ErrorBoundary from "./ErrorBoundary";
import Routes from "./routes";
import Loader from "./components/styled/Loader";
import Empty from "./layouts/Empty";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate={
          process.env.NODE_ENV === "production"
            ? "%s - Spur"
            : "%s - Spur Staging"
        }
        defaultTitle="Dashboard and Account Management - Spur"
      />
      <StylesProvider injectFirst>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <Suspense
                fallback={
                  <Empty>
                    <Loader />
                  </Empty>
                }
              >
                <ErrorBoundary>
                  <Routes />
                  <GlobalDialog />
                  <GlobalErrorDialog />
                  <GlobalToast />
                </ErrorBoundary>
              </Suspense>
            </AuthProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
