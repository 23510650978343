import React, { useEffect } from "react";
import Fab from "@mui/material/Fab";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useLocation } from "react-router-dom";
import Box, { BoxProps } from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import MultiSchemaHelmet from "../components/MultiSchemaHelmet";

const Root: React.FC<BoxProps> = ({ ...rest }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        minHeight: "100vh",
        mx: 2,
      }}
      {...rest}
    />
  );
};

export const ScrollToTopOnChange: React.FC<any> = () => {
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const el = document.getElementById(hash.replace("#", ""));
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, search, hash]);

  return null;
};
interface MainProps {
  resetScrollOnChange?: boolean;
  children?: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ resetScrollOnChange, children }: any) => {
  return (
    <div>
      <MultiSchemaHelmet />
      {resetScrollOnChange && <ScrollToTopOnChange />}
      <Root>
        <CssBaseline />
        <Box sx={{ position: "absolute" }} id="header-anchor">
          <Header />
        </Box>
        <Container fixed maxWidth="lg">
          {children}
        </Container>
        <ScrollToTop anchorTag="#header-anchor">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollToTop>
      </Root>
    </div>
  );
};

export default Main;
