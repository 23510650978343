import { Elements } from "@stripe/react-stripe-js";
import { parse, stringify } from "query-string";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Empty from "../../layouts/Empty";
import { AppState } from "../../redux/store";
import {
  fetchAvailablePlans,
  fetchCurrentSubscription,
  fetchDefaultPaymentMethod,
} from "../../redux/store/account/actions";
import Complete from "./Complete";
import Payment from "./Payment";
import SelectPlan from "./SelectPlan";

import { loadStripe } from "@stripe/stripe-js";

let token = "";
if (process.env.REACT_APP_ENVIRONMENT === "production") {
  token = "pk_live_CUii38S76fdxdCTsM802sLWR";
} else {
  token = "pk_test_jz1ZC2OGSBpJZm8uiFuZ4oMt";
}

const stripeClient = loadStripe(token, {
  apiVersion: "2020-08-27",
});

const client = stripeClient;

export interface CheckoutLocationState {
  plan?: string;
  price?: string;
  term?: "annual" | "monthly";
  status?: string;
  invId?: string;
  coSess?: string;
}

const Checkout: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { account } = useSelector((state: AppState) => state);
  const colState = {
    term: "annual",
    ...parse(location.search),
  } as CheckoutLocationState;

  // Setter for url state bar
  const setState = (params: CheckoutLocationState) => {
    history.push({
      ...location,
      search: stringify(params),
    });
  };

  // Pre-fetch required datas
  useEffect(() => {
    batch(() => {
      dispatch(fetchCurrentSubscription());
      dispatch(fetchDefaultPaymentMethod());
      dispatch(fetchAvailablePlans());
    });
  }, [dispatch]);

  return (
    <Empty>
      <Elements stripe={client}>
        <Switch>
          <Route
            key={"checkout-select-plan"}
            path={"/checkout/select-plan"}
            exact
            render={() => (
              <SelectPlan
                colState={colState}
                account={account}
                setState={setState}
              />
            )}
          />
          <Route
            key={"checkout-payment"}
            path={"/checkout/payment"}
            exact
            render={() => (
              <Payment
                colState={colState}
                account={account}
                setState={setState}
              />
            )}
          />
          <Route
            key={"checkout-complete"}
            path={"/checkout/complete"}
            exact
            render={() => (
              <Complete
                colState={colState}
                account={account}
                setState={setState}
              />
            )}
          />
          <Route render={() => <Redirect to="/checkout/select-plan" />} />
        </Switch>
      </Elements>
    </Empty>
  );
};

export default Checkout;
