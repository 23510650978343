import React, { FunctionComponent, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../redux/store/account/actions";
import { DOMLinkButton } from "./styled/Buttons";
import AppBar from "@mui/material/AppBar";
import { Menu as MenuIcon } from "@mui/icons-material";
import { NavLink, useLocation } from "react-router-dom";
import Slide from "@mui/material/Slide";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Icon from "@mui/material/Icon";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Div from "./styled/Div";
import { AuthContext } from "../contexts/Auth";

const SlidOutOnScroll: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const MenuNav: React.FC<{ children: React.ReactElement } & DrawerProps> = ({
  children,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div style={{ margin: "10px" }}>
      <Icon
        onClick={() => {
          setOpen(!open);
        }}
      >
        <MenuIcon style={{ fontSize: 30 }} />
      </Icon>
      <Drawer
        {...rest}
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        {children}
      </Drawer>
    </div>
  );
};

type HeaderProps = {};
const Header: FunctionComponent<HeaderProps> = ({ ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user }: any = useContext(AuthContext);

  return (
    <Div sx={{ flexDirection: "column" }} {...rest}>
      <SlidOutOnScroll>
        <AppBar
          sx={(t) => ({
            flexGrow: 1,
            height: "60px",
            width: "100%",
            justifyContent: "center",
          })}
          elevation={1}
        >
          <Container fixed maxWidth="lg">
            <Toolbar
              sx={(t) => ({
                [t.breakpoints.down("md")]: {
                  display: "none",
                  visibility: "hidden",
                },
              })}
            >
              <Link href="/">
                <img alt="logo" height={28} src="horizontal_logo_white.svg" />
              </Link>
              <MenuList
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: 1,
                }}
              >
                <MenuItem component={DOMLinkButton} to="/">
                  Context
                </MenuItem>
                <MenuItem component={NavLink} disabled={!user} to="/monocle">
                  Monocle
                </MenuItem>
                <MenuItem component={NavLink} disabled={!user} to="/account">
                  Account
                </MenuItem>
                {user ? (
                  <MenuItem
                    sx={{
                      border: "2px solid",
                      borderRadius: 25,
                      margin: "10px",
                    }}
                    component={Button}
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    Sign Out
                  </MenuItem>
                ) : (
                  <MenuItem
                    sx={{
                      margin: "10px",
                    }}
                    to="/auth/sign-in"
                    component={NavLink}
                  >
                    Sign In
                  </MenuItem>
                )}
              </MenuList>
            </Toolbar>
            <Toolbar
              sx={(t) => ({
                [t.breakpoints.up("md")]: {
                  display: "none",
                  visibility: "hidden",
                },
              })}
            >
              <Link href="/">
                <img alt="logo" height={28} src="horizontal_logo_white.svg" />
              </Link>
              <div style={{ marginLeft: "auto" }}>
                <MenuNav
                  PaperProps={{
                    sx: (t) => ({
                      // backgroundColor: t.palette.primary.main,
                      // color: t.palette.primary.contrastText,
                    }),
                  }}
                >
                  <Stack sx={{ height: "100%", m: 3, py: 3 }}>
                    <MenuList>
                      <MenuItem component={Link} href="/">
                        Home
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        to="/"
                        selected={location.pathname === "/context"}
                      >
                        Context
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        disabled={!user}
                        to="/monocle"
                        selected={location.pathname === "/monocle"}
                      >
                        Monocle
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        disabled={!user}
                        to="/account"
                        selected={location.pathname === "/account"}
                      >
                        Account
                      </MenuItem>
                    </MenuList>
                    <MenuList style={{ marginTop: "auto" }}>
                      {user ? (
                        <MenuItem
                          component={Button}
                          onClick={() => {
                            dispatch(logout());
                          }}
                        >
                          Sign Out
                        </MenuItem>
                      ) : (
                        <MenuItem component={NavLink} to="/auth/sign-in">
                          Sign In
                        </MenuItem>
                      )}
                    </MenuList>
                  </Stack>
                </MenuNav>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </SlidOutOnScroll>
    </Div>
  );
};

export default Header;
