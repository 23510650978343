import React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Zoom from "@mui/material/Zoom";
import Div from "./styled/Div";

interface Props {
  anchorTag: string;
  children: React.ReactElement;
}

function ScrollToTop(props: Props) {
  const { children, anchorTag } = props;
  // const history = useHistory();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    // target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector(anchorTag);

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start" });
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
  };

  return (
    <Zoom in={trigger}>
      <Div
        onClick={handleClick}
        role="presentation"
        sx={(t) => ({
          position: "fixed",
          bottom: t.spacing(2),
          right: t.spacing(2),
        })}
      >
        {children}
      </Div>
    </Zoom>
  );
}

export default ScrollToTop;
