import { Helmet, HelmetProps } from "react-helmet";
import { siteURL } from "../config";

interface MultiSchemaHelmetProps extends HelmetProps {
  description?: string;
  cononical?: string;
  themeColor?: string;
  image?: string;
  canonical?: string;
}

export const MultiSchemaHelmet: React.FC<MultiSchemaHelmetProps> = ({
  title = "Context Search and Dashboard - Spur",
  description = "Log-in to use our community search tool to view data and analyze anonymous infrastructure. Our data on VPNs and Proxies makes the internet a safer place to do business.",
  themeColor = "#000000",
  image = "/img/ctx_search_blank.png",
  canonical,
}) => {
  const path = window.location.pathname;
  const url = canonical ? `${siteURL}${canonical}` : `${siteURL}${path}`;
  return (
    <Helmet>
      <link rel="canonical" href={url} />

      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="twitter:title" content={title} />
      <meta property="og:title" content={title} />

      <meta property="url" content={path} />
      <meta property="og:url" content={path} />
      <meta property="twitter:url" content={path} />

      <meta name="description" content={description} />
      <meta property="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="twitter:description" content={description} />

      <meta property="image" content={image} />
      <meta property="og:image" content={image} />
      <meta property="twitter:image" content={image} />

      <meta name="theme-color" content={themeColor} />
    </Helmet>
  );
};

export default MultiSchemaHelmet;
