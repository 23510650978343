import React, { Component } from "react";
import { connect } from "react-redux";
import { AppState } from "../../redux/store";
import { NotificationState } from "../../redux/store/notification/types";
import { toggleGlobalToastOpen } from "../../redux/store/notification/actions";

import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

class GlobalToast extends Component<{
  notification: NotificationState;
  toggleGlobalToastOpen: typeof toggleGlobalToastOpen;
}> {
  render() {
    const { notification, toggleGlobalToastOpen } = this.props;
    const { open, message } = notification.globalToast;
    return (
      <React.Fragment>
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={3500}
          onClose={toggleGlobalToastOpen}
          message={message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={toggleGlobalToastOpen}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  notification: state.notification,
});

export default connect(mapStateToProps, { toggleGlobalToastOpen })(GlobalToast);
