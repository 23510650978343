import { auth } from "../../../firebase";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  AccountState,
  LOGOUT,
  RESET_STORE,
  SET_TOKENS,
  SET_AVAILABLE_PLANS,
  SET_USER,
  SET_DEFAULT_PAYMENT_METHOD,
  SET_PAYMENT_METHODS,
  UPDATE_TOKEN,
  AccountActionOptions,
  SET_CURRENT_SUBSCRIPTION,
  SET_CURRENT_SUBSCRIPTION_FETCHING,
  SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING,
  SET_SUBSCRIPTION_CHANGE_PREVIEW,
} from "./types";
import fetch, { fetchJSON } from "../../../lib/fetch";
import {
  PaymentMethodSummary,
  PlanInfo,
  SubscriptionInfo,
  Token,
  UserSummary,
} from "../../../../../common";
import {
  setGlobalDialog,
  setGlobalErrorDialog,
  setGlobalToast,
} from "../notification/actions";
import { batch } from "react-redux";

export const resetStore = () => {
  return {
    type: RESET_STORE,
  };
};

export const setUser =
  (
    user: UserSummary | undefined
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    dispatch({ type: SET_USER, payload: user });
  };

export const logout =
  (): ThunkAction<void, AccountState, null, Action> => async (dispatch) => {
    await auth.signOut();
    dispatch({ type: LOGOUT });
  };

export const fetchUser =
  (): ThunkAction<void, AccountState, null, Action> => async (dispatch) => {
    try {
      const { data }: { data: UserSummary } = await fetchJSON(`/api/v1/user`);
      if (data) {
        dispatch({ type: SET_USER, payload: data });
      }
    } catch (err) {
      console.error(err);
    }
  };

export const fetchAvailablePlans =
  (): ThunkAction<void, AccountState, null, Action> => async (dispatch) => {
    try {
      const { data }: { data: PlanInfo[] } = await fetchJSON(`/api/v1/plans`);
      dispatch({ type: SET_AVAILABLE_PLANS, payload: data });
    } catch (err) {
      console.error(err);
    }
  };

export const fetchCurrentSubscription =
  (): ThunkAction<void, AccountState, null, Action> => async (dispatch) => {
    try {
      dispatch({
        type: SET_CURRENT_SUBSCRIPTION_FETCHING,
        payload: true,
      });
      const {
        data,
      }: {
        data: SubscriptionInfo | undefined;
      } = await fetchJSON(`/api/v1/user/subscription`);
      batch(() => {
        dispatch({
          type: SET_CURRENT_SUBSCRIPTION,
          payload: data,
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

export const fetchDefaultPaymentMethod =
  (): ThunkAction<void, AccountState, null, Action> => async (dispatch) => {
    try {
      const { data }: { data: PaymentMethodSummary } = await fetchJSON(
        `/api/v1/paymentMethods/default`
      );
      dispatch({ type: SET_DEFAULT_PAYMENT_METHOD, payload: data });
    } catch (err) {
      console.error(err);
    }
  };

export const fetchPaymentMethods =
  (): ThunkAction<void, AccountState, null, Action> => async (dispatch) => {
    try {
      const { data }: { data: PaymentMethodSummary } = await fetchJSON(
        `/api/v1/paymentMethods/`
      );
      dispatch({ type: SET_PAYMENT_METHODS, payload: data });
    } catch (err) {
      console.error(err);
    }
  };

export const fetchTokens =
  (): ThunkAction<void, AccountState, null, Action> => async (dispatch) => {
    try {
      const { data }: { data: Token[] } = await fetchJSON(`/api/v1/tokens`);
      // console.log(data);
      dispatch({ type: SET_TOKENS, payload: data });
    } catch (err) {
      console.error(err);
    }
  };

export const resetToken =
  (
    token: Token,
    opts?: AccountActionOptions
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    try {
      const { data }: { data: Token[] } = await fetchJSON(
        `/api/v1/tokens/${token.value}`,
        {
          method: "DELETE",
        }
      );
      dispatch({ type: UPDATE_TOKEN, payload: data });
      if (opts?.notify) {
        dispatch(
          setGlobalToast({
            open: true,
            message: "Token Reset.",
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

export const setUserPassword =
  (
    value: string,
    opts?: AccountActionOptions
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    try {
      if (auth.currentUser) {
        await auth.currentUser.updatePassword(value);
        if (opts?.notify) {
          dispatch(
            setGlobalToast({
              open: true,
              message: "Password Updated",
            })
          );
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(
        setGlobalToast({
          open: true,
          message: "Unable to Set Password",
        })
      );
    }
  };

export const setUserProfile =
  (
    data: any,
    opts?: AccountActionOptions
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    // console.log(data);
    try {
      await fetchJSON(`/api/v1/user/profile`, {
        method: "POST",
        json: { ...data },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const clearPreviewUserPlan = () => {
  return {
    type: SET_SUBSCRIPTION_CHANGE_PREVIEW,
    payload: undefined,
  };
};

export const previewUserPlan =
  (
    {
      newPlanId,
      newPriceId,
      currentPlanId,
      currentPriceId,
      subscriptionId,
    }: {
      newPlanId: string;
      newPriceId: string;
      currentPlanId?: string;
      currentPriceId?: string;
      subscriptionId?: string | undefined;
    },
    opts?: AccountActionOptions
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    dispatch({ type: SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING, payload: true });
    try {
      const { data } = await fetchJSON(`/api/v1/invoices/preview`, {
        method: "POST",
        json: {
          newPlanId,
          newPriceId,
          currentPlanId,
          currentPriceId,
          subscriptionId,
        },
      });
      dispatch({ type: SET_SUBSCRIPTION_CHANGE_PREVIEW, payload: data });
    } catch (err) {
      console.error(err);
    }
  };

interface CancelUserSubscriptionOpts extends AccountActionOptions {
  when?: "now";
}

export const cancelUserSubscription =
  (
    subscriptionId: string,
    opts?: CancelUserSubscriptionOpts
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    dispatch({ type: SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING, payload: true });
    try {
      await fetchJSON(`/api/v1/user/subscription/${subscriptionId}`, {
        method: "DELETE",
        queryParams: { ...(opts?.when === "now" && { when: "now" }) },
      });
      // dispatch({ type: SET_SUBSCRIPTION_CHANGE_PREVIEW, payload: data });
      batch(() => {
        if (opts?.notify) {
          dispatch(
            setGlobalToast({
              open: true,
              message: "Subscription Updated",
            })
          );
        }
        dispatch(fetchCurrentSubscription());
      });
    } catch (err) {
      console.error(err);
    }
  };

export const undoCancelUserSubscription =
  (
    subscriptionId: string,
    opts?: AccountActionOptions
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    dispatch({ type: SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING, payload: true });
    try {
      await fetchJSON(`/api/v1/user/subscription/${subscriptionId}`, {
        method: "POST",
        json: { cancelAtEnd: false },
      });
      batch(() => {
        if (opts?.notify) {
          dispatch(
            setGlobalToast({
              open: true,
              message: "Subscription Updated",
            })
          );
        }
        dispatch(fetchCurrentSubscription());
      });
    } catch (err) {
      console.error(err);
    }
  };

export const setUserEmail =
  (
    email: string,
    opts?: AccountActionOptions
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    try {
      if (auth.currentUser) {
        await auth.currentUser.updateEmail(email);
        if (opts?.notify) {
          dispatch(
            setGlobalToast({
              open: true,
              message: "Email Updated",
            })
          );
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(
        setGlobalToast({
          open: true,
          message: "Unable to Set Email.",
        })
      );
    }
  };

export const resetPassword =
  (
    email?: string,
    opts?: AccountActionOptions
  ): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    try {
      await fetchJSON(`/api/v1/user/password`, {
        ...(email && { queryParams: { email } }),
        method: "DELETE",
      });
      if (opts?.notify) {
        dispatch(
          setGlobalToast({
            open: true,
            message: "Password Reset Sent.",
          })
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        setGlobalErrorDialog({
          open: true,
          title: "Error",
          message:
            "Failed to send password reset. Please try again later or contact support.",
        })
      );
    }
  };

interface AccessRequest {
  q: string;
  justification: string;
}

export const requestSensitiveAccess =
  (r: AccessRequest): ThunkAction<void, AccountState, null, Action> =>
  async (dispatch) => {
    try {
      await fetchJSON(`/api/v1/user/tlp`, {
        json: r,
        method: "POST",
      });
      dispatch(
        setGlobalDialog({
          open: true,
          title: "Access Request Submitted",
          message:
            "Our account team will review your request. Any updates to your request will be provided through email.",
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        setGlobalErrorDialog({
          open: true,
          title: "Error",
          message:
            "Failed to send access request. Please try again later or contact support.",
        })
      );
    }
  };

export const deleteAccount =
  (): ThunkAction<void, AccountState, null, Action> => async (dispatch) => {
    try {
      await fetch(`/api/v1/user`, {
        method: "DELETE",
      });
      dispatch(logout());
    } catch (err) {
      console.error(err);
      dispatch(
        setGlobalErrorDialog({
          open: true,
          title: "Error",
          message:
            "Failed to delete account. Please try again later or contact support.",
        })
      );
    }
  };
