import React, { FunctionComponent } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { connect } from "react-redux";
import MultiSchemaHelmet from "../components/MultiSchemaHelmet";
import Empty from "../layouts/Empty";
import { resetStore } from "../redux/store/account/actions";

const PageError: FunctionComponent<{ resetStore: typeof resetStore }> = ({
  resetStore,
}) => {
  resetStore();
  return (
    <Empty>
      <MultiSchemaHelmet
        title="Unexpected Error"
        description="This page encountered an error and is unavailable."
        canonical="/error"
      />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Uh Oh
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Looks like we ran into an error.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed, is invalid, or
        some functionality might have changed. Please try refreshing your
        browser or navigating to another page.
      </Typography>
      <Box mt={5} display="flex" justifyContent="center">
        <Button component={Link} href="/" variant="contained" color="primary">
          Home
        </Button>
      </Box>
    </Empty>
  );
};

export default connect(null, { resetStore })(PageError);
