export interface NotificationState {
  globalDialog: GlobalDialogState;
  globalErrorDialog: GlobalErrorDialogState;
  globalToast: GlobalToastState;
}

export interface GlobalDialogState {
  title: string;
  message: string | React.ReactFragment;
  open: boolean;
}

export interface GlobalErrorDialogState {
  title: string;
  message: string;
  open: boolean;
}

export interface GlobalToastState {
  message: string;
  open: boolean;
  color?: string;
}

export const TOGGLE_GLOBAL_DIALOG_STATE_OPEN =
  "notification/TOGGLE_GLOBAL_DIALOG_STATE_OPEN";
export const SET_GLOBAL_DIALOG_STATE = "notification/SET_GLOBAL_DIALOG_STATE";

export const TOGGLE_GLOBAL_ERROR_DIALOG_STATE_OPEN =
  "notification/TOGGLE_GLOBAL_ERROR_DIALOG_STATE_OPEN";
export const SET_GLOBAL_ERROR_DIALOG_STATE =
  "notification/SET_GLOBAL_ERROR_DIALOG_STATE";

export const TOGGLE_GLOBAL_TOAST_STATE_OPEN =
  "notification/TOGGLE_GLOBAL_TOAST_STATE_OPEN";
export const SET_GLOBAL_TOAST_STATE = "notification/SET_GLOBAL_TOAST_STATE";

interface ToggleGlobalDialogStateOpenAction {
  type: typeof TOGGLE_GLOBAL_DIALOG_STATE_OPEN;
}

interface SetGlobalDialogStateAction {
  type: typeof SET_GLOBAL_DIALOG_STATE;
  payload: GlobalDialogState;
}

interface ToggleGlobalErrorDialogStateOpenAction {
  type: typeof TOGGLE_GLOBAL_ERROR_DIALOG_STATE_OPEN;
}

interface SetGlobalErrorDialogStateAction {
  type: typeof SET_GLOBAL_ERROR_DIALOG_STATE;
  payload: GlobalErrorDialogState;
}

interface ToggleGlobalToastStateOpenAction {
  type: typeof TOGGLE_GLOBAL_TOAST_STATE_OPEN;
}

interface SetGlobalToastStateAction {
  type: typeof SET_GLOBAL_TOAST_STATE;
  payload: GlobalToastState;
}

export type NotificationActionTypes =
  | SetGlobalDialogStateAction
  | SetGlobalErrorDialogStateAction
  | SetGlobalToastStateAction
  | ToggleGlobalDialogStateOpenAction
  | ToggleGlobalErrorDialogStateOpenAction
  | ToggleGlobalToastStateOpenAction;
