import React, { Component } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { AppState } from "../../redux/store";
import { NotificationState } from "../../redux/store/notification/types";
import { toggleGlobalErrorDialogOpen } from "../../redux/store/notification/actions";
import ResponsiveDialog from "../ResponsiveDialog";

class GlobalErrorDialog extends Component<{
  notification: NotificationState;
  toggleGlobalErrorDialogOpen: typeof toggleGlobalErrorDialogOpen;
}> {
  render() {
    const { notification, toggleGlobalErrorDialogOpen } = this.props;
    const { open, title, message } = notification.globalErrorDialog;
    return (
      <React.Fragment>
        <ResponsiveDialog
          fullWidth
          open={open}
          onClose={toggleGlobalErrorDialogOpen}
          aria-labelledby="global-error-dialog-title"
        >
          <DialogTitle id="global-error-dialog-title">{title}</DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            <Button
              onClick={toggleGlobalErrorDialogOpen}
              color="primary"
              autoFocus
              type="submit"
            >
              Okay
            </Button>
          </DialogActions>
        </ResponsiveDialog>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  notification: state.notification,
});

export default connect(mapStateToProps, { toggleGlobalErrorDialogOpen })(
  GlobalErrorDialog
);
