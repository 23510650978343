import React from "react";
import { fetchJSON } from "./lib/fetch";
import PageError from "./pages/Error";

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch = async (error: any, info: any) => {
    this.setState({ hasError: true });
    try {
      fetchJSON(`/api/v1/logs/errors`, {
        method: "POST",
        json: {
          timestamp: new Date(),
          error,
          info,
        },
      });
    } catch (err) {}
  };

  render() {
    if (this.state.hasError) {
      return <PageError />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
