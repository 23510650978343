import {
  NotificationState,
  NotificationActionTypes,
  SET_GLOBAL_DIALOG_STATE,
  SET_GLOBAL_ERROR_DIALOG_STATE,
  SET_GLOBAL_TOAST_STATE,
  TOGGLE_GLOBAL_DIALOG_STATE_OPEN,
  TOGGLE_GLOBAL_ERROR_DIALOG_STATE_OPEN,
  TOGGLE_GLOBAL_TOAST_STATE_OPEN,
} from "./types";

const initialState: NotificationState = {
  globalDialog: {
    title: "",
    message: "",
    open: false,
  },
  globalErrorDialog: {
    title: "",
    message: "",
    open: false,
  },
  globalToast: {
    message: "",
    open: false,
  },
};

export function notificationReducer(
  state = initialState,
  action: NotificationActionTypes
): NotificationState {
  switch (action.type) {
    case SET_GLOBAL_DIALOG_STATE: {
      return {
        ...state,
        globalDialog: action.payload,
      };
    }
    case SET_GLOBAL_ERROR_DIALOG_STATE: {
      return {
        ...state,
        globalErrorDialog: action.payload,
      };
    }
    case SET_GLOBAL_TOAST_STATE: {
      return {
        ...state,
        globalToast: action.payload,
      };
    }
    case TOGGLE_GLOBAL_DIALOG_STATE_OPEN: {
      return {
        ...state,
        globalDialog: initialState.globalDialog,
      };
    }
    case TOGGLE_GLOBAL_ERROR_DIALOG_STATE_OPEN: {
      return {
        ...state,
        globalErrorDialog: initialState.globalErrorDialog,
      };
    }
    case TOGGLE_GLOBAL_TOAST_STATE_OPEN: {
      return {
        ...state,
        globalToast: initialState.globalToast,
      };
    }
    default:
      return state;
  }
}
