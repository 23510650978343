import React, { Component, ReactNode } from "react";
import AppUpdatedErrorMessage from "../components/AppUpdatedErrorMessage";

interface LazyLoadErrorBoundaryProps {
  children: ReactNode;
  onChunkLoadError?: () => void;
}

interface LazyLoadErrorBoundaryState {
  hasError: boolean;
}

class LazyLoadErrorBoundary extends Component<
  LazyLoadErrorBoundaryProps,
  LazyLoadErrorBoundaryState
> {
  constructor(props: LazyLoadErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): LazyLoadErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    if (
      /loading chunk [\d]+ failed/.test(error.message) &&
      this.props.onChunkLoadError
    ) {
      this.props.onChunkLoadError();
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <AppUpdatedErrorMessage />;
    }
    return this.props.children;
  }
}

export default LazyLoadErrorBoundary;
