import { Search } from "../../../../../common";

export interface DashboardState {
  autocomplete?: string[];
  showRawData?: boolean;

  isFetchingSearch?: boolean;
  isFetchingSearchError?: string;
  searchResults?: Search.Result;

  isFetchingStats?: boolean;
  isFetchingStatsError?: string;
  statsResults?: Search.Result;
}

export const CLEAR_STATE = "dashboard/CLEAR_STATE";
export const IS_FETCHING_SEARCH = "dashboard/IS_FETCHING_SEARCH";
export const IS_FETCHING_STATS = "dashboard/IS_FETCHING_STATS";
export const IS_FETCHING_SEARCH_ERROR = "dashboard/IS_FETCHING_SEARCH_ERROR";
export const IS_FETCHING_STATS_ERROR = "dashboard/IS_FETCHING_STATS_ERROR";
export const RECEIVE_AUTOCOMPLETE_DICT = "dashboard/RECEIVE_AUTOCOMPLETE_DICT";
export const RECEIVE_SEARCH_RESULTS = "dashboard/RECEIVE_SEARCH_RESULTS";
export const RECEIVE_STATS_RESULTS = "dashboard/RECEIVE_STATS_RESULTS";
export const TOGGLE_SHOW_RAW_DATA = "dashboard/TOGGLE_SHOW_RAW_DATA";

interface IsFetchingSearchAction {
  type: typeof IS_FETCHING_SEARCH;
}
interface IsFetchingStatsAction {
  type: typeof IS_FETCHING_STATS;
}

interface ToggleShowRawDataAction {
  type: typeof TOGGLE_SHOW_RAW_DATA;
}

interface IsFetchingSearchErrorAction {
  type: typeof IS_FETCHING_SEARCH_ERROR;
  payload: string;
}
interface IsFetchingStatsErrorAction {
  type: typeof IS_FETCHING_STATS_ERROR;
  payload: string;
}

interface ReceiveAutocompleteDictAction {
  type: typeof RECEIVE_AUTOCOMPLETE_DICT;
  payload: string[];
}

interface ReceiveSearchResultsAction {
  type: typeof RECEIVE_SEARCH_RESULTS;
  payload: Search.Result;
}

interface ReceiveStatsResultsAction {
  type: typeof RECEIVE_STATS_RESULTS;
  payload: Search.Result;
}

export type DashboardActionTypes =
  | IsFetchingSearchAction
  | IsFetchingStatsAction
  | IsFetchingSearchErrorAction
  | IsFetchingStatsErrorAction
  | ReceiveAutocompleteDictAction
  | ReceiveSearchResultsAction
  | ReceiveStatsResultsAction
  | ToggleShowRawDataAction;
