import React from "react";
import Typography from "@mui/material/Typography";
import { InvoicePreview } from "../../../../common";
import { formatCurrency } from "../../locale";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

interface InvoicePreviewCardProps {
  preview?: InvoicePreview;
}

const InvoicePreviewCard: React.FC<InvoicePreviewCardProps> = ({ preview }) => {
  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "flex-end",
        py: 3,
      }}
    >
      {preview?.items.map((l, i) => (
        <Grid key={i} container justifyContent="space-between">
          <Typography
            variant="caption"
            style={{
              display: "block",
              fontFamily: "Monospace",
              textAlign: "left",
            }}
          >
            {l.description}
          </Typography>
          <Typography
            key={i}
            variant="caption"
            style={{
              display: "block",
              fontFamily: "Monospace",
              textAlign: "right",
            }}
          >
            {formatCurrency(l.price, true)}
          </Typography>
        </Grid>
      ))}

      <Divider />

      <div style={{ textAlign: "right", display: "block" }}>
        <Typography
          style={{
            fontWeight: "bold",
            display: "inline-block",
            fontFamily: "Monospace",
            textAlign: "right",
          }}
        >
          Due Today:
        </Typography>{" "}
        <Typography
          style={{
            fontFamily: "Monospace",
            display: "inline-block",
            textAlign: "right",
          }}
        >
          {formatCurrency(preview?.amount || 0, true)}
        </Typography>
      </div>
    </Box>
  );
};

export default InvoicePreviewCard;
