import React from "react";
import Typography from "@mui/material/Typography";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Card, { CardProps } from "@mui/material/Card";

interface PaymentMethodProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  last4: string;
  brand?: string;
  isDefault?: boolean;
  selected?: boolean;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  last4,
  brand,
  selected,
  isDefault,
  ...rest
}) => {
  let icon;
  switch (true) {
    case brand === "visa":
      icon = faCcVisa;
      break;
    case brand === "mastercard":
      icon = faCcMastercard;
      break;
    case brand === "amex":
      icon = faCcAmex;
      break;
    case brand === "discover":
      icon = faCcDiscover;
      break;
    case brand === "diners":
      icon = faCcDinersClub;
      break;
    case brand === "jcb":
      icon = faCcJcb;
      break;
    case brand === "unionpay":
    case brand === "unknown":
    default:
      icon = faCreditCard;
  }

  return (
    <Card
      component={Box}
      {...rest}
      sx={(t) => ({
        height: 120,
        width: 200,
        display: "flex",
        backgroundColor: t.palette.primary.main,
        color: t.palette.primary.contrastText,
        alignItems: "center",
        justifyContent: "space-between",
        p: 4,
        cursor: "pointer",
        ...(selected && { border: `3px solid ${t.palette.success.main}` }),
      })}
    >
      <FontAwesomeIcon icon={icon} color="white" size="3x" />
      <Typography style={{ fontFamily: "Monospace" }}>...{last4}</Typography>
    </Card>
  );
};

export default PaymentMethod;

export const BlankPaymentMethod: React.FC<CardProps> = ({ ...rest }) => {
  return (
    <Card
      component={Box}
      sx={(t) => ({
        height: 120,
        width: 200,
        display: "flex",
        backgroundColor: t.palette.primary.main,
        color: t.palette.primary.contrastText,
        alignItems: "center",
        justifyContent: "space-between",
        p: 4,
        cursor: "pointer",
      })}
      {...rest}
    >
      <FontAwesomeIcon icon={faPlus} color="white" size="2x" />
      <Typography style={{ fontFamily: "Monospace" }}>Add Card</Typography>
    </Card>
  );
};
