import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Empty from "../../layouts/Empty";
import Container from "@mui/material/Container";
import { CheckoutLocationState } from ".";
import { AccountState } from "../../redux/store/account/types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import InvoicePreviewCard from "../../components/checkout/InvoicePreview";
import {
  fetchDefaultPaymentMethod,
  fetchPaymentMethods,
  previewUserPlan,
} from "../../redux/store/account/actions";
import { batch, useDispatch } from "react-redux";
import { ArrowBack, ExitToApp } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import PaymentMethod, {
  BlankPaymentMethod,
} from "../../components/checkout/PaymentMethod";
import Loader from "../../components/styled/Loader";
import Section from "../../components/Section";
import { fetchJSON } from "../../lib/fetch";
import { stringify } from "query-string";
import { AddPaymentMethodDialog } from "../../components/checkout/AddPaymentMethod";
import { setGlobalErrorDialog } from "../../redux/store/notification/actions";
import Box from "@mui/material/Box";
import Stack from "@mui/system/Stack";

interface PaymentProps {
  account: AccountState;
  colState: CheckoutLocationState;
  setState: (state: CheckoutLocationState) => any;
}

const Payment: React.FC<PaymentProps> = ({ colState, account, setState }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState<{ tos: boolean; pm?: string }>({
    tos: false,
    pm: account.defaultPaymentMethod?.id,
  });

  const [addPaymentMethodOpen, setAddPaymentMethodOpen] = useState(false);
  const [submittingPurchase, setSubmittingPurchase] = useState(false);

  const redirectToStripePaymentMethodPortal = async () => {
    try {
      const { data } = await fetchJSON("/api/v1/paymentMethods/portal", {
        queryParams: {
          returnTo: `${location.pathname}${location.search}`,
        },
      });
      document.location.href = data.url;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    batch(() => {
      dispatch(fetchDefaultPaymentMethod());
      dispatch(fetchPaymentMethods());
    });
  }, [dispatch]);

  useEffect(() => {
    setFormState((s) => ({ ...s, pm: account.defaultPaymentMethod?.id }));
  }, [account.defaultPaymentMethod]);

  useEffect(() => {
    if (colState.plan && colState.price) {
      dispatch(
        previewUserPlan({
          newPlanId: colState.plan,
          newPriceId: colState.price,
          subscriptionId: account.currentSubscription?.id,
        })
      );
    }
  }, [
    colState.plan,
    colState.price,
    colState.term,
    account.currentSubscription?.id,
    dispatch,
  ]);

  const submitForm = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setSubmittingPurchase(true);
    await fetchJSON("/api/v1/user/subscription", {
      method: "POST",
      json: {
        planId: colState.plan,
        priceId: colState.price,
        pm: formState.pm,
        subscriptionId: account.currentSubscription?.id,
      },
    })
      .catch((err) => {
        dispatch(
          setGlobalErrorDialog({
            open: true,
            title: "An Error Occurred",
            message: `An error occurred while completing your purchase. Please try again later or contact support@spur.us for assistance. ${err}`,
          })
        );
      })
      .then(({ data }) => {
        history.push({
          pathname: "/checkout/complete",
          search: stringify({
            plan: data.plan.id,
            status: "success",
            invId: data.lastInvoice,
          }),
        });
      })
      .finally(() => {
        setSubmittingPurchase(false);
      });
  };

  return (
    <Empty>
      <Helmet title="Checkout" />
      {(!colState.plan || !colState.price) && (
        <Redirect to="/checkout/select-plan" />
      )}
      <Container maxWidth="md">
        <Button
          startIcon={<ArrowBack />}
          component={RouterLink}
          to={{ ...location, pathname: "/checkout/select-plan" }}
        >
          Back
        </Button>
        <Box textAlign={"center"} sx={{ py: 2 }}>
          <Typography variant="h3">Checkout</Typography>
        </Box>

        <form onSubmit={submitForm}>
          <Stack gap={3}>
            <Section title="Purchase Summary">
              {/* <Subsection title="Purchase Summary" /> */}
              <Box sx={{ my: 2 }}>
                {account.subscriptionChangePreviewFetching ? (
                  <Loader />
                ) : (
                  <InvoicePreviewCard
                    preview={account.subscriptionChangePreview}
                  />
                )}
              </Box>
            </Section>
            <Section
              title="Select Payment Method"
              subtitle={"Add or select a payment method to continue."}
            >
              <Box sx={{ my: 4 }}>
                <Grid
                  container
                  sx={(t) => ({
                    gap: t.spacing(4),
                    py: 3,
                    justifyContent: "flex-end",
                    [t.breakpoints.down("lg")]: {
                      justifyContent: "center",
                    },
                  })}
                >
                  {account.paymentMethods &&
                    account.paymentMethods.map((pm) => (
                      <PaymentMethod
                        key={pm.id}
                        selected={formState.pm === pm.id}
                        id={pm.id}
                        last4={pm.last4}
                        brand={pm.brand}
                        onClick={() => {
                          setFormState((s) => ({ ...s, pm: pm.id }));
                        }}
                      />
                    ))}
                  <BlankPaymentMethod
                    onClick={() => {
                      setAddPaymentMethodOpen((s) => !s);
                    }}
                  />
                </Grid>
                <Grid
                  container
                  sx={(t) => ({
                    justifyContent: "flex-end",
                    [t.breakpoints.down("lg")]: {
                      justifyContent: "center",
                    },
                  })}
                >
                  {account.paymentMethods &&
                    account.paymentMethods.length > 0 && (
                      <Button
                        variant="contained"
                        endIcon={<ExitToApp />}
                        onClick={redirectToStripePaymentMethodPortal}
                      >
                        Mange in Stripe
                      </Button>
                    )}
                </Grid>
              </Box>
            </Section>

            <Section
              title="Terms and Conditions"
              subtitle={
                "By continuing with this purchase you agree with the following:"
              }
            >
              <FormControlLabel
                style={{ textAlign: "left" }}
                control={
                  <Checkbox
                    required
                    checked={formState.tos}
                    onChange={() => {
                      setFormState((s) => {
                        return { ...s, tos: !s.tos };
                      });
                    }}
                    id="tos"
                    name="tos"
                    size="small"
                  />
                }
                label={
                  <Typography variant="caption">
                    I have read, understood, and agree to Spur's{" "}
                    <Link
                      href={`https://spur.us/terms/website`}
                      underline="always"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions.
                    </Link>{" "}
                    I specifically acknowledge that under this license: Spur
                    Data <b>MAY NOT</b> be redistributed, proxied, or resold to
                    third-parties. Spur Data <b>MAY NOT</b> be reverse
                    engineered or used to develop competing products. Spur Data
                    is licensed exclusively for internal security and fraud
                    purposes only
                  </Typography>
                }
              />

              <Grid container style={{ padding: 48 }}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    fullWidth
                    style={{ maxWidth: 300 }}
                    variant="contained"
                    type="submit"
                    disabled={
                      !formState.pm || !formState.tos || submittingPurchase
                    }
                  >
                    Complete Purchase
                  </Button>
                </Grid>
              </Grid>
            </Section>
          </Stack>
        </form>
      </Container>
      <AddPaymentMethodDialog
        open={addPaymentMethodOpen}
        onPaymentMethodAdded={() => {
          batch(() => {
            dispatch(fetchDefaultPaymentMethod());
            dispatch(fetchPaymentMethods());
          });
          setAddPaymentMethodOpen((s) => !s);
        }}
        onClose={() => {
          setAddPaymentMethodOpen((s) => !s);
        }}
      />
    </Empty>
  );
};

export default Payment;
