// import { siteURL } from "../config";
import { auth } from "../firebase";
import store from "../redux/store";
import { LOGOUT } from "../redux/store/account/types";

interface ExtendedRequestInit extends RequestInit, ExtendedOptions {}

interface ExtendedOptions {
  queryParams?: any;
  json?: any;
}

/**
 * An extended fetch operation to automatically build query strings
 * @param url
 * @param options
 */
// const wrappedFetch = (url: string, options: any = {}) => {
const wrappedFetch = async (
  info: RequestInfo,
  options?: ExtendedRequestInit
): Promise<Response> => {
  let newOptions = options;
  if (!newOptions) {
    newOptions = {} as ExtendedRequestInit;
  }

  // If typeof info is string and starts with "/" we should
  // add our full API url to the request
  // We need this if the api is on a different domain
  // if (typeof info === "string" && info.startsWith("/api")) {
  //   info = `${siteURL}${info}`;
  //   console.log("new info", info);
  // }

  // Handle building out query parameters for the request
  if (newOptions.queryParams && typeof info === "string") {
    info +=
      (info.indexOf("?") === -1 ? "?" : "&") +
      queryParams(newOptions.queryParams);
    delete newOptions.queryParams;
  }

  // Handle adding JSON with headers to the request
  // this will overwrite any exiting body data
  if (newOptions.json) {
    newOptions.body = JSON.stringify(newOptions.json);
    const newHeaders = new Headers(newOptions.headers);
    newHeaders.set("Content-Type", "application/json");
    newOptions.headers = newHeaders;
  }

  // If we have a firebase user, enrich with our bearer auth
  if (auth.currentUser) {
    const t = await auth.currentUser.getIdToken();
    const newHeaders = new Headers(newOptions.headers);
    newHeaders.set("Authorization", `Bearer ${t}`);
    newOptions.headers = newHeaders;
  }
  return fetch(info, newOptions).then((r) => {
    // If we get a 401 for any reason, force a new user session.
    if (r.status === 401) {
      auth.signOut();
      store.dispatch({ type: LOGOUT });
    }
    return r;
  });
};

export const queryParams = (params: any) => {
  return Object.keys(params)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
};

export const fetchJSON = async (
  info: RequestInfo,
  options?: ExtendedRequestInit
): Promise<any> => {
  return wrappedFetch(info, options).then((r) => r.json());
};

export default wrappedFetch;
