import {
  MonocleDeployment,
  MonocleToken,
  MonocleUser,
} from "../../../../../common";

export interface MonocleState {
  user?: MonocleUser;
  keys: MonocleDeployment[];
  deployments: { [id: string]: MonocleDeployment };
  deploymentsLoading?: boolean;
  tokens: {
    [deploymentId: string]: { [id: string]: MonocleToken };
  };
  privkeyCreated?: MonocleDeployment;
  privkeyCreatedError?: string;
  monocleDeploymentsAllowed?: number;
  monocleDeploymentsCreated?: number;
  monocleDeploymentStats?: {
    [hash: string]: MonocleDeploymentStats;
  };
  siteTokensLoading?: string;
}

export interface MonocleDeploymentStats {
  AnonCount: number; // total anonymous
  ProxiedCount: number; // total proxied
  VpnCount: number; // total vpn
  TotalAssessmentsCount: number; // total assessments completed
  TotalLoadCount: number; // total monocles loaded
  UniqueIPV4Count: number; // total ipv4
  UniqueIPV6Count: number; // total ipv6
  TopServices: {
    [tag: string]: number; // TAG_NAME: count, can also include NONE
  };
  ByDay: {
    // e.g. 2023-05-01
    [date: string]: {
      AnonCount: number; // anonymous
      ProxiedCount: number; // proxied
      VpnCount: number; // vpns
      AssessmentCount: number; // assessments completed
      LoadCount: number; // fetches
    };
  };
}

export const SET_MONOCLE_DEPLOYMENTS = "monocle/SET_MONOCLE_DEPLOYMENTS";

interface SetMonocleDeploymentsAction {
  type: typeof SET_MONOCLE_DEPLOYMENTS;
  payload: MonocleDeployment[];
}
export const SET_MONOCLE_DEPLOYMENTS_LOADING =
  "monocle/SET_MONOCLE_DEPLOYMENTS_LOADING";

interface SetMonocleDeploymentsLoadingAction {
  type: typeof SET_MONOCLE_DEPLOYMENTS_LOADING;
  payload: boolean;
}

export const SET_SITE_TOKENS_LOADING = "monocle/SET_SITE_TOKENS_LOADING";

interface SetSiteTokensLoading {
  type: typeof SET_SITE_TOKENS_LOADING;
  payload: string;
}

export const CREATE_MONOCLE_KEY = "monocle/CREATE_MONOCLE_KEY";

interface CreateMonocleDeploymentAction {
  type: typeof CREATE_MONOCLE_KEY;
  payload: MonocleDeployment;
}
export const CREATE_MONOCLE_DEPLOYMENT_TOKEN =
  "monocle/CREATE_MONOCLE_DEPLOYMENT_TOKEN";

interface CreateMonocleDeploymentTokenAction {
  type: typeof CREATE_MONOCLE_DEPLOYMENT_TOKEN;
  parent: string;
  payload: MonocleToken;
}

export const DELETE_MONOCLE_KEY = "monocle/DELETE_MONOCLE_KEY";

interface DeleteMonocleDeploymentAction {
  type: typeof DELETE_MONOCLE_KEY;
  payload: string;
}

export const DELETE_MONOCLE_DEPLOYMENT_TOKEN =
  "monocle/DELETE_MONOCLE_DEPLOYMENT_TOKEN";

interface DeleteMonocleDeploymentTokenAction {
  type: typeof DELETE_MONOCLE_DEPLOYMENT_TOKEN;
  parent: string;
  payload: string;
}

export const SET_MONOCLE_DEPLOYMENT_TOKENS =
  "monocle/SET_MONOCLE_DEPLOYMENT_TOKENS";

interface SetMonocleDeploymentTokensAction {
  type: typeof SET_MONOCLE_DEPLOYMENT_TOKENS;
  parent: string;
  payload: MonocleToken[];
}
export const SET_MONOCLE_DEPLOYMENT_STATS =
  "monocle/SET_MONOCLE_DEPLOYMENT_STATS";

interface SetMonocleDeploymentStatsAction {
  type: typeof SET_MONOCLE_DEPLOYMENT_STATS;
  payload: { [hash: string]: MonocleDeploymentStats };
}

export const SET_MONOCLE_PRIVKEY_CREATED =
  "monocle/SET_MONOCLE_PRIVKEY_CREATED";

interface SetMonoclePrivkeyCreatedAction {
  type: typeof SET_MONOCLE_PRIVKEY_CREATED;
  payload: MonocleDeployment | undefined;
}

export const SET_MONOCLE_PRIVKEY_CREATED_ERROR =
  "monocle/SET_MONOCLE_PRIVKEY_CREATED_ERROR";

interface SetMonoclePrivkeyCreatedErrorAction {
  type: typeof SET_MONOCLE_PRIVKEY_CREATED_ERROR;
  payload: string | undefined;
}

export const SET_MONOCLE_DEPLOYMENTS_REMAINING =
  "monocle/SET_MONOCLE_DEPLOYMENTS_REMAINING";

interface SetMonocleDeploymentsRemainingAction {
  type: typeof SET_MONOCLE_DEPLOYMENTS_REMAINING;
  payload:
    | { monocleDeploymentsAllowed: number; monocleDeploymentsCreated: number }
    | undefined;
}

export type MonocleActionTypes =
  | CreateMonocleDeploymentAction
  | CreateMonocleDeploymentTokenAction
  | DeleteMonocleDeploymentAction
  | DeleteMonocleDeploymentTokenAction
  | SetMonoclePrivkeyCreatedErrorAction
  | SetMonocleDeploymentsAction
  | SetMonocleDeploymentTokensAction
  | SetMonocleDeploymentStatsAction
  | SetMonocleDeploymentsRemainingAction
  | SetMonoclePrivkeyCreatedAction
  | SetMonocleDeploymentsLoadingAction
  | SetSiteTokensLoading;
