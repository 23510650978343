import { keyframes } from "@mui/system";

export const slideFadeIn = keyframes(`
  from {
    opacity: 0;
    transform: "translateY(-20px)";
  }
  to {
    opacity: 1;
    transform: "translateY(0)";
  }
`);

export const fadeIn = keyframes(`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`);

export const appear = keyframes(`
  to {
    visibility: visible;
  }
`);
