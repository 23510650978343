import { Store, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  createMigrate,
  MigrationManifest,
  PersistedState,
} from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { accountReducer } from "./account/reducers";
import { monocleReducer } from "./monocle/reducers";
import { notificationReducer } from "./notification/reducers";
import { LOGOUT, RESET_STORE } from "./account/types";
import { dashboardReducer } from "./dashboard/reducers";

// Create a single reducers from all of our stores
const reducers = combineReducers({
  account: accountReducer,
  dashboard: dashboardReducer,
  monocle: monocleReducer,
  notification: notificationReducer,
});
const rootReducer = (state: any, action: any) => {
  // console.log(action)
  if (action.type === LOGOUT || action.type === RESET_STORE) {
    state = undefined;
  }
  return reducers(state, action);
};
export type AppState = ReturnType<typeof rootReducer>;

// Create a generic migration to wipe all redux state.
// Easier to set this up sooner rather than later.
const migrations: MigrationManifest = {
  1: () => {
    return undefined as PersistedState;
  },
};

// Persist things in local storage
const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
  version: 3,
  migrate: createMigrate(migrations, { debug: false }),
};
const composeEnhancers = composeWithDevTools({});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [thunk];

let store: Store;

// Do store differently for dev
if (process.env.NODE_ENV === "development") {
  store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );
} else {
  store = createStore(persistedReducer, applyMiddleware(...middlewares));
}

export default store;
export const persistor = persistStore(store);
