import { MonocleToken } from "../../../../../common";
import {
  CREATE_MONOCLE_KEY,
  CREATE_MONOCLE_DEPLOYMENT_TOKEN,
  DELETE_MONOCLE_KEY,
  DELETE_MONOCLE_DEPLOYMENT_TOKEN,
  MonocleActionTypes,
  MonocleState,
  SET_MONOCLE_DEPLOYMENTS,
  SET_MONOCLE_DEPLOYMENTS_LOADING,
  SET_MONOCLE_DEPLOYMENTS_REMAINING,
  SET_MONOCLE_DEPLOYMENT_STATS,
  SET_MONOCLE_DEPLOYMENT_TOKENS,
  SET_MONOCLE_PRIVKEY_CREATED,
  SET_MONOCLE_PRIVKEY_CREATED_ERROR,
  SET_SITE_TOKENS_LOADING,
} from "./types";

const initialState: MonocleState = {
  user: undefined,
  keys: [],
  deployments: {},
  deploymentsLoading: false,
  tokens: {},
  privkeyCreated: undefined,
  monocleDeploymentStats: {},
  siteTokensLoading: undefined,
};

export function monocleReducer(
  state = initialState,
  action: MonocleActionTypes
): MonocleState {
  switch (action.type) {
    case CREATE_MONOCLE_KEY: {
      let newState = { ...state };
      newState.deployments[action.payload.id] = action.payload;
      return newState;
    }
    case DELETE_MONOCLE_KEY: {
      let newState = { ...state };
      delete newState.deployments[action.payload];
      return newState;
    }
    case CREATE_MONOCLE_DEPLOYMENT_TOKEN: {
      let newState = { ...state };
      newState.tokens[action.parent][action.payload.id] = action.payload;
      return newState;
    }
    case DELETE_MONOCLE_DEPLOYMENT_TOKEN: {
      let newState = { ...state };
      delete newState.tokens[action.parent][action.payload];
      return newState;
    }
    case SET_MONOCLE_DEPLOYMENTS: {
      return {
        ...state,
        keys: action.payload,
        deployments: action.payload.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {}
        ),
      };
    }
    case SET_MONOCLE_DEPLOYMENT_TOKENS: {
      let tokenMap: {
        [deploymentId: string]: { [tokenId: string]: MonocleToken };
      } = {};
      if (action.payload.length === 0) {
        tokenMap = { [action.parent]: {} };
      } else {
        tokenMap = action.payload.reduce(
          (
            acc: {
              [deploymentId: string]: { [tokenId: string]: MonocleToken };
            },
            token
          ) => {
            if (!acc[token.parentKey]) {
              acc[token.parentKey] = {};
            }
            acc[token.parentKey][token.id] = token;
            return acc;
          },
          {}
        );
      }
      return {
        ...state,
        tokens: {
          ...state.tokens,
          ...tokenMap,
        },
      };
    }
    case SET_MONOCLE_DEPLOYMENT_STATS: {
      return {
        ...state,
        monocleDeploymentStats: action.payload,
      };
    }
    case SET_MONOCLE_PRIVKEY_CREATED: {
      return {
        ...state,
        privkeyCreated: action.payload,
      };
    }
    case SET_MONOCLE_PRIVKEY_CREATED_ERROR: {
      return {
        ...state,
        privkeyCreatedError: action.payload,
      };
    }
    case SET_MONOCLE_DEPLOYMENTS_REMAINING: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_SITE_TOKENS_LOADING: {
      return {
        ...state,
        siteTokensLoading: action.payload,
      };
    }
    case SET_MONOCLE_DEPLOYMENTS_LOADING: {
      return {
        ...state,
        deploymentsLoading: action.payload,
      };
    }

    default:
      return state;
  }
}
