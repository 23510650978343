import React, { useEffect, useState, FunctionComponent, Context } from "react";
import Loader from "../components/styled/Loader";
import { auth } from "../firebase";
import Empty from "../layouts/Empty";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/store/account/actions";
import { UserSummary } from "../../../common";
import firebase from "firebase";

export const AuthContext: Context<{
  loaded?: boolean;
  user?: firebase.User | null;
}> = React.createContext({});

export const AuthProvider: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch();
  const [userContext, setUserContext] = useState({
    loaded: false,
    user: auth.currentUser,
  });

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setUserContext({ loaded: true, user } as any);
      if (user) {
        const {
          uid,
          email,
          emailVerified,
          phoneNumber,
          metadata: { lastSignInTime, creationTime },
        } = user;
        dispatch(
          setUser({
            uid,
            email,
            emailVerified,
            phoneNumber,
            lastSignInTime,
            creationTime,
          } as UserSummary)
        );
      } else {
        dispatch(setUser(undefined));
      }
    });
  }, [dispatch]);

  // NOTE: This is frequently the cause of any jitter while firebase loads
  // and checks auth. We can probably improve perceived performance in our
  // render if not yet loaded.
  return (
    <AuthContext.Provider value={userContext}>
      {userContext.user || userContext.loaded ? (
        children
      ) : (
        <Empty>
          <Loader />
        </Empty>
      )}
    </AuthContext.Provider>
  );
};
