import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import ResponsiveDialog, { ResponsiveDialogProps } from "../ResponsiveDialog";
import { fetchJSON } from "../../lib/fetch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

interface AddPaymentMethodProps extends React.HTMLAttributes<HTMLDivElement> {
  onPaymentMethodAdded?: () => any;
}

const AddPaymentMethod: React.FC<AddPaymentMethodProps> = ({
  onPaymentMethodAdded,
  ...rest
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  if (!elements || !stripe) {
    return null;
  }

  const attachCard = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const card = elements.getElement(CardElement);
    if (!card) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: card,
      });
      if (error) {
        setError(error.message);
        return;
      }

      await fetchJSON(`/api/v1/paymentMethods/default`, {
        method: "POST",
        json: {
          pmId: paymentMethod?.id,
        },
      });
      if (onPaymentMethodAdded) {
        await onPaymentMethodAdded();
      }
    } catch (err) {
      setError(
        "An error has occurred adding your payment method. Please try again later or contact support@spur.us."
      );
    }

    setIsLoading(false);
  };

  return (
    <Box
      sx={(t) => ({
        width: t.breakpoints.values.sm,
        display: "flex",
        flexDirection: "column",
        gap: t.spacing(3),
        textAlign: "center",
        padding: t.spacing(4),
      })}
      {...rest}
    >
      {error ? (
        <div>
          <Typography color="error">{error}</Typography>
        </div>
      ) : (
        <form onSubmit={attachCard}>
          <CardElement
            options={{
              style: {
                base: {
                  fontFamily: "Monospace",
                  fontSize: "16px",
                  fontSmoothing: "antialiased",
                },
              },
            }}
          />
          <div style={{ textAlign: "center", marginTop: "3rem" }}>
            <Button
              type="submit"
              variant="contained"
              style={{ maxWidth: 300 }}
              fullWidth
              color="primary"
              disabled={!stripe || isLoading}
            >
              Add Payment Method
            </Button>
          </div>
        </form>
      )}
    </Box>
  );
};

export default AddPaymentMethod;

interface AddPaymentMethodDialogProps
  extends ResponsiveDialogProps,
    AddPaymentMethodProps {}

export const AddPaymentMethodDialog: React.FC<AddPaymentMethodDialogProps> = ({
  onPaymentMethodAdded,
  onClose,
  ...rest
}) => {
  return (
    <ResponsiveDialog onClose={onClose} closeFab {...rest}>
      <DialogTitle id="add-payment-method-title">
        Add Payment Method
      </DialogTitle>
      <DialogContent
        sx={(t) => ({
          display: "flex",
          justifyContent: "center",
          [t.breakpoints.down("lg")]: {
            paddingTop: t.spacing(10),
          },
        })}
      >
        <AddPaymentMethod onPaymentMethodAdded={onPaymentMethodAdded} />
      </DialogContent>
    </ResponsiveDialog>
  );
};
