import {
  DashboardActionTypes,
  DashboardState,
  IS_FETCHING_SEARCH,
  IS_FETCHING_SEARCH_ERROR,
  IS_FETCHING_STATS,
  IS_FETCHING_STATS_ERROR,
  RECEIVE_AUTOCOMPLETE_DICT,
  RECEIVE_SEARCH_RESULTS,
  RECEIVE_STATS_RESULTS,
  TOGGLE_SHOW_RAW_DATA,
} from "./types";

const initialState: DashboardState = {
  isFetchingSearch: false,
  isFetchingSearchError: undefined,
  searchResults: undefined,
  showRawData: false,

  isFetchingStats: false,
  isFetchingStatsError: undefined,
  statsResults: undefined,

  autocomplete: [],
};

export function dashboardReducer(
  state = initialState,
  action: DashboardActionTypes
): DashboardState {
  switch (action.type) {
    case IS_FETCHING_SEARCH: {
      return {
        ...state,
        isFetchingSearch: true,
        isFetchingSearchError: undefined,
      };
    }
    case IS_FETCHING_SEARCH_ERROR: {
      return {
        ...state,
        isFetchingSearchError: action.payload,
        isFetchingSearch: false,
      };
    }
    case IS_FETCHING_STATS: {
      return {
        ...state,
        isFetchingStats: true,
        isFetchingStatsError: undefined,
      };
    }
    case IS_FETCHING_STATS_ERROR: {
      return {
        ...state,
        isFetchingStatsError: action.payload,
        isFetchingStats: false,
      };
    }
    case RECEIVE_AUTOCOMPLETE_DICT: {
      return {
        ...state,
        autocomplete: action.payload,
      };
    }
    case RECEIVE_SEARCH_RESULTS: {
      return {
        ...state,
        isFetchingSearch: false,
        isFetchingSearchError: undefined,
        searchResults: action.payload,
      };
    }
    case RECEIVE_STATS_RESULTS: {
      return {
        ...state,
        isFetchingStats: false,
        isFetchingStatsError: undefined,
        statsResults: action.payload,
      };
    }
    case TOGGLE_SHOW_RAW_DATA: {
      return {
        ...state,
        showRawData: !state.showRawData,
      };
    }
    default:
      return state;
  }
}
