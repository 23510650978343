import {
  AccountState,
  AccountActionTypes,
  SET_USER,
  SET_AVAILABLE_PLANS,
  SET_TOKENS,
  SET_DEFAULT_PAYMENT_METHOD,
  UPDATE_TOKEN,
  SET_CURRENT_SUBSCRIPTION,
  SET_CURRENT_SUBSCRIPTION_FETCHING,
  SET_AVAILABLE_PLANS_FETCHING,
  SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING,
  SET_SUBSCRIPTION_CHANGE_PREVIEW,
  SET_PAYMENT_METHODS,
} from "./types";

const initialState: AccountState = {
  user: undefined,
  tokens: undefined,

  currentSubscription: undefined,
  currentSubscriptionFetching: undefined,
  subscriptionChangePreview: undefined,
  subscriptionChangePreviewFetching: undefined,

  availablePlans: undefined,
  availablePlansFetching: undefined,

  defaultPaymentMethod: undefined,
  paymentMethods: undefined,
};

export function accountReducer(
  state = initialState,
  action: AccountActionTypes
): AccountState {
  switch (action.type) {
    case SET_AVAILABLE_PLANS: {
      return {
        ...state,
        availablePlans: action.payload,
        availablePlansFetching: false,
      };
    }
    case SET_AVAILABLE_PLANS_FETCHING: {
      return {
        ...state,
        availablePlansFetching: action.payload,
      };
    }
    case SET_DEFAULT_PAYMENT_METHOD: {
      return {
        ...state,
        defaultPaymentMethod: action.payload,
      };
    }
    case SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: action.payload,
      };
    }
    case SET_CURRENT_SUBSCRIPTION: {
      return {
        ...state,
        currentSubscriptionFetching: false,
        currentSubscription: action.payload,
      };
    }
    case SET_CURRENT_SUBSCRIPTION_FETCHING: {
      return {
        ...state,
        currentSubscriptionFetching: action.payload,
      };
    }
    case SET_SUBSCRIPTION_CHANGE_PREVIEW_FETCHING: {
      return {
        ...state,
        ...(action.payload && { subscriptionChangePreview: undefined }),
        subscriptionChangePreviewFetching: action.payload,
      };
    }
    case SET_SUBSCRIPTION_CHANGE_PREVIEW: {
      return {
        ...state,
        subscriptionChangePreviewFetching: false,
        subscriptionChangePreview: action.payload,
      };
    }
    case SET_TOKENS: {
      return {
        ...state,
        tokens: action.payload,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case UPDATE_TOKEN: {
      return {
        ...state,
        tokens: [
          ...(state.tokens || []).filter((t) => {
            return t.type !== action.payload?.type;
          }),
          action.payload,
        ],
      };
    }

    default:
      return state;
  }
}
