import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box, { BoxProps } from "@mui/material/Box";
import { Container as MuiContainer, styled } from "@mui/material";
import { ScrollToTopOnChange } from "./Main";
import MultiSchemaHelmet from "../components/MultiSchemaHelmet";

const Root = styled(Box)({
  minHeight: "100vh",
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Container = styled(MuiContainer)({});

interface EmptyProps extends BoxProps {
  resetScrollOnChange?: boolean;
}

const Empty: React.FC<EmptyProps> = ({
  resetScrollOnChange,
  children,
}: any) => {
  return (
    <Root>
      <MultiSchemaHelmet />
      {resetScrollOnChange && <ScrollToTopOnChange />}
      <CssBaseline />
      <Container fixed maxWidth="lg">
        {children}
      </Container>
    </Root>
  );
};

export default Empty;
